import { RobotsEnum } from './types'
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';
import { getThumbnailPath } from '@vue-storefront/core/helpers';

export function metaPromotionPage () {
  const relRobots = relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);
  const metaTitle = this.getCurrentPage.meta_title
  const metaDescription = this.getCurrentPage.meta_description
  const bannerMobilePath = getThumbnailPath(this.getCurrentPage.mobile_image)
  const bannerDesktopPath = getThumbnailPath(this.getCurrentPage.desktop_image)

  const meta = {
    title: metaTitle || `${this.$t('Stock')} | VARUS`,
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content: metaDescription || `${this.$t('Stock')} ${this.$t('OTHER_PAGES_DESCRIPTION')}`
      }
    },
    link: [
      {
        rel: 'preload',
        href: bannerMobilePath || bannerDesktopPath || '',
        as: 'image'
      }
    ]
  };

  return mergeMeta(meta)
}
